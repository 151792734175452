// extracted by mini-css-extract-plugin
export var container = "layout-module--container--eLBMS";
export var allpage = "layout-module--allpage--8wwSw";
export var navstyle = "layout-module--navstyle--6NbLC";
export var heading = "layout-module--heading--8VjLO";
export var navLinks = "layout-module--nav-links--EROwB";
export var navLinkItem = "layout-module--nav-link-item--pfCo2";
export var navLinkText = "layout-module--nav-link-text--ac2nV";
export var contentLinkText = "layout-module--content-link-text--uROQj";
export var footer = "layout-module--footer--rX0Dt";
export var f2 = "layout-module--f2--AZyAh";
export var f3 = "layout-module--f3--XHi3b";
export var f4 = "layout-module--f4--g5Cec";
export var footerleft = "layout-module--footerleft--CLO4E";
export var copyright = "layout-module--copyright--7omyd";